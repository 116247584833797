module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PoiGoi","short_name":"PoiGoi","start_url":"/","background_color":"#ffffff","theme_color":"#99ccff","display":"standalone","icon":"src/images/poifuture-logo-clip.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"globPatterns":["**/*.html","*.js","**/*.json","manifest.webmanifest"],"ignoreUrlParametersMatching":[{}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
