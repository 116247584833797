// Follow Ant Design
// https://ant.design/docs/spec/colors

export const poisky = "#99ccff"
export const poisky1 = "#f0faff"
export const poisky2 = "#f0f9ff"
export const poisky3 = "#f0f9ff"
export const poisky4 = "#ebf6ff"
export const poisky5 = "#c2e2ff"
export const poisky6 = "#99ccff"
export const poisky7 = "#77a5d9"
export const poisky8 = "#5980b3"
export const poisky9 = "#3f5e8c"
export const poisky10 = "#2d4266"
